import { createSlice } from '@reduxjs/toolkit';
import { dispatch } from '../store';
import axiosInstance from '../../utils/axios';
import { API_BASE_URLS } from '../../utils/constant';
import { AddressDataType, CityDataType, CountryDataType, StateDataType } from '../../@customTypes/address';

type AddressState = {
  isLoading: boolean;
  error: boolean;
  addressList: AddressDataType[];
  CityList: CityDataType[];
  StateList: StateDataType[];
  CountryList: CountryDataType[];
  selectedAddress: AddressDataType | null;
  selectedCountry: number | null;
  selectedState: number | null;
  totalpage: number;
};

const initialState: AddressState = {
  isLoading: false,
  error: false,
  addressList: [],
  CityList: [],
  StateList: [],
  CountryList: [],
  selectedAddress: null,
  selectedCountry: null,
  selectedState: null,
  totalpage: 0,
};

const slice = createSlice({
  name: 'address',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // COMPANY LISTING
    getAddressListSuccess(state, action) {
      state.isLoading = false;
      state.addressList = action.payload;
    },
    getcityListSuccess(state, action) {
      state.isLoading = false;
      state.CityList = action.payload;
    },
    getStateListSuccess(state, action) {
      state.isLoading = false;
      state.StateList = action.payload;
    },
    getCountryListSuccess(state, action) {
      state.isLoading = false;
      state.CountryList = action.payload;
    },
    getSelectedAddressSuccess(state, action) {
      state.isLoading = false;
      state.selectedAddress = action.payload;
    },
    setSelectedAddress(state, action) {
      state.isLoading = false;
      state.selectedAddress = action.payload;
    },
    setSelectedCountry(state, action) {
      // const data = state.CountryList.find((obj:CountryDataType)=>obj.id===action.payload)
      state.isLoading = false;
      state.selectedCountry = action.payload;
    },
    setSelectedState(state, action) {
      state.isLoading = false;
      state.selectedState = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

export function getAddressById({ companyId, id }: { companyId: number; id: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.address}/${id}`);
      dispatch(slice.actions.getSelectedAddressSuccess(response?.data || {}));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddressList({ companyId, filterData }: { companyId: number; filterData: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.getAddressList}`, filterData);
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getAddressListSuccess(response?.data || []));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function addNewAddress({ companyId, addressDetails }: { companyId: number; addressDetails: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.address}/add`, addressDetails);

      if (response?.error) {
        throw new Error(response?.error);
      }
      return response.data;
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function editAddressDetails({ companyId, addressDetails }: { companyId: number; addressDetails: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    const { id, ...restAddressDetails } = addressDetails;
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.address}/${id}/edit`, restAddressDetails);
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function deleteAddress({ companyId, id }: { companyId: number; id: any }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.delete(`${API_BASE_URLS.company}/${companyId}${API_BASE_URLS.address}/${id}/delete`);
      if (response?.error) {
        throw new Error(response?.error);
      }
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getCountryList() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.getCountryList}`);
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getCountryListSuccess(response?.data || []));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getStateList({ country_id }: { country_id: number }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.country}/${country_id}${API_BASE_URLS.getStateList}`);
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getStateListSuccess(response?.data || []));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function getCityList({ state_id, country_id }: { state_id: number; country_id: number }) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response: any = await axiosInstance.post(`${API_BASE_URLS.country}/${country_id}${API_BASE_URLS.state}/${state_id}${API_BASE_URLS.getCityList}`);
      if (response?.error) {
        throw new Error(response?.error);
      } else dispatch(slice.actions.getcityListSuccess(response?.data || []));
    } catch (error: any) {
      dispatch(slice.actions.hasError(error));
      throw new Error(error.message);
    }
  };
}

export function setSelectedAddress(payload: any) {
  return async () => dispatch(slice.actions.setSelectedAddress(payload));
}
export function setSelectedCountry(payload: any) {
  return async () => dispatch(slice.actions.setSelectedCountry(payload));
}
export function setSelectedSate(payload: any) {
  return async () => dispatch(slice.actions.setSelectedState(payload));
}
