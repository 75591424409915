import { createContext, ReactNode, useCallback, useEffect, useReducer, useState } from 'react';
import { API_BASE_URLS } from '../utils/constant';
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@customTypes/authentication';
import axiosInstance from '../utils/axios';

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Activate = 'ACTIVATE',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: { isAuthenticated: boolean; user: AuthUser };
  [Types.Login]: { user: AuthUser };
  [Types.Logout]: undefined;
  [Types.Register]: { user: AuthUser };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case 'LOGIN':
      return { ...state, isAuthenticated: true, user: action.payload.user };
    case 'LOGOUT':
      return { ...state, isAuthenticated: false, user: null };
    case 'REGISTER':
      return { ...state, isAuthenticated: true, user: action.payload.user };
    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const [coordinates, setCoordinates] = useState<{
    latitude: string;
    longitude: string;
  }>({ latitude: '', longitude: '' });

  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        return navigator.geolocation.getCurrentPosition(getPosition);
      }
    };
    function getPosition(position: any) {
      setCoordinates({
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      });
    }
    getLocation();
  }, []);

  const refresh = useCallback(
    async (refresh_token: string | null, user: object) => {
      try {
        const response: any = await axiosInstance.post(API_BASE_URLS.refresh, {
          refresh_token,
        });
        if (!response || response?.error) {
          throw new Error(response?.error || 'Something went wrong');
        }
        const { token, refreshToken } = response.data;
        const tokenDetails = { accessToken: token, refreshToken: refreshToken };
        setSession(tokenDetails, null);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user: { ...user, coordinates: coordinates },
          },
        });
      } catch (error) {
        setSession(null, null);
        window.location.href = '/auth/login';
      }
    },
    [coordinates]
  );

  const initialize = useCallback(async () => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const refreshToken = window.localStorage.getItem('refreshToken');
      const user = window.localStorage.getItem('user') || '';

      if (accessToken && user && isValidToken(accessToken)) {
        setSession({ accessToken, refreshToken: refreshToken || '' }, JSON.parse(user));

        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: true,
            user: { ...JSON.parse(user), coordinates: coordinates },
          },
        });
      } else {
        refresh(refreshToken, {
          ...JSON.parse(user),
          coordinates: coordinates,
        });
      }
    } catch (err) {
      dispatch({
        type: Types.Initial,
        payload: { isAuthenticated: false, user: null },
      });
    }
  }, [coordinates, refresh]);

  useEffect(() => {
    initialize();
  }, [coordinates, initialize]);

  const login = async (email: string, password: string) => {
    const response: any = await axiosInstance.post(API_BASE_URLS.login, {
      email,
      password,
    });
    if (!response || response?.error) {
      throw new Error(response?.error || 'Something went wrong');
    }
    const { token, refreshToken, ...userDetails } = response.data;
    const user = {
      ...userDetails,
      coordinates: coordinates,
    };
    const tokenDetails = {
      accessToken: token,
      refreshToken: refreshToken,
    };
    setSession(tokenDetails, user);
    setSession(tokenDetails, user);
    dispatch({ type: Types.Login, payload: { user } });
  };

  const resetPassword = async (email: string) => {
    const response: any = await axiosInstance.post(API_BASE_URLS.resetpassword, { email });
    if (response?.error) {
      throw new Error(response?.error);
    }
  };

  const verifyOTP = async (email: string | null, otp: number) => {
    const response: any = await axiosInstance.post(API_BASE_URLS.verifyOTP, {
      email,
      otp,
    });
    if (response?.error) {
      throw new Error(response?.error);
    }
    localStorage.setItem('updateToken', response.data.updateToken);
  };

  const updatePassword = async (password: string) => {
    let token = localStorage.getItem('updateToken');
    const response: any = await axiosInstance.post(API_BASE_URLS.updatePassword, { password }, { headers: { Authorization: token } });
    if (response?.error) {
      throw new Error(response?.error);
    }
  };

  const changePassword = async (currentPassword: string, newPassword: string) => {
    const token = window.localStorage.getItem('accessToken');
    const response: any = await axiosInstance.post(API_BASE_URLS.changePassword, { currentPassword, newPassword }, { headers: { Authorization: `Bearer ${token}` } });
    if (!response || response?.error) {
      throw new Error(response?.error || 'Something went wrong');
    }
    return response.data;
  };

  const resendPassword = async (email: string | null) => {
    const response: any = await axiosInstance.post(API_BASE_URLS.resendOTP, {
      email,
    });
    if (response?.error) {
      throw new Error(response?.error);
    }
  };

  const register = async (email: string, password: string, firstName: string, lastName: string) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({ type: Types.Register, payload: { user } });
  };

  const logout = async () => {
    setSession(null, null);
    dispatch({ type: Types.Logout });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        verifyOTP,
        resetPassword,
        updatePassword,
        changePassword,
        resendPassword,
        logout,
        register,
        initialize,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
